
.component-table {
    max-width: 100%;
    height: 100%;
    overflow: scroll;
    margin-top: 30px;
}

.table {
    min-width: 90%;
    margin: 30px auto;
    border-spacing: 0 2px;
}


.table th{
    font-size: 15px;
    padding: 15px;
    color: white;
}

.table tr {
    cursor: pointer;
}

.table td {
    padding: 15px;
    background-color: rgb(255, 255, 255);
    font-size: 14px;
    white-space: nowrap;
    text-align: center;
}

.table .no-click {
    cursor:initial;
}
