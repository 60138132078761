.settings-container {
    max-width: 75%;
    padding: 40px;
    background-color: white;
    margin: auto;
}

.delivering-setting {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin:40px auto;
}

.btn-success {
    padding: 10px 20px;
    background-color: black;
    color: white;
    max-height: 30px;
    align-self: center;
    text-align: center;
    cursor: pointer;
}
