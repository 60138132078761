.page-container {
    width:calc(100% - 20px);
    height: calc(100% - 130px);
    padding: 20px;
    position: relative;
}

.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}
