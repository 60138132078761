.tabsbar {
    width: 75%;
    margin: auto;
    display: flex;
    justify-content: center;
}

.tab {
    padding: 10px;
    cursor: pointer;
}

.tab-selected {
    padding: 10px;
    cursor: pointer;
    background-color: black;
    color: white;
}
