.buttons {
    display: flex;
    justify-content: space-between;
}

.read-only-container {
    margin: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    position: relative;
}

.container-item {
    width: 90%;
    width: 90%;
    margin: 30px 75px;
    background-color: white;
}

.custom-form-field {
    width: auto;
}
