.checkbox {
    width: 10px;
    height: 10px;
    border : 1px solid black;
    cursor: pointer;
    margin-left: 20px;
}
.checkbox-active {
    background-color: black;
}

.checkbox-inactive {
    background-color: white;
}

.checkbox-container {
    width: 100%;
    margin: 15px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    position: relative;
}
