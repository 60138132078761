.list-cards {
    width: 100%;
}

.container-card {
    width: 250px;
    height: 250px;
    float: left;
    padding: 20px;
}
