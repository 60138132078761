.container-collections {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.modal-collection {
    width: 60%;
    min-height: 100px;
    margin: 20px auto;
    background-color: white;
    padding: 20px;
    text-align: center;
    max-height: 80%;
    overflow: scroll;
}

.modal-collection .title {
    font-size: 25px;
}
