.app-container {
  height: 100%;
  overflow: hidden;
}

label {
  letter-spacing: 2;
}

.btn {
  padding: 5px 20px;
  border-radius: 2px;
  cursor: pointer;
  display:block;
  margin: 10px auto;
  text-align: center;
}


.label {
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
}


.bg-success {
  background-color: rgb(66, 223, 134);
  color: white;
}

.bg-info {
  background-color: rgb(66, 69, 223);
  color: white;
}

.bg-danger {
  background-color: rgb(255, 110, 110);
  color: rgb(255, 255, 255);
}

.bg-dark {
  background-color: #373737;
  color: white;
}

.bg-dark-2 {
  background-color: #727374;
  color: white;
}

.bg-light {
  background-color: #e8e8e8;
  color: rgb(0, 0, 0);
}

.bg-white {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.bg-black {
  background-color: #000000;
  color: rgb(255, 255, 255);
}

.container-modal {
  background-color: rgba(0,0,0,0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  top : 0;
  left: 0;
  align-items: center;
}

.container-modal-hide {
  display: none;
}

.input {
  display: block;
  padding: 10px;
  margin: 0 20px;
  width: 60%;
}

.input:focus {
  border: none;
}

.w-10 {
  width: 10%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}
