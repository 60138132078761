.container-customers {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y:scroll;
}

.modal-customer-hide {
    display: none;
}
.modal-customer {
    position: absolute;
    z-index: 1;
    width: calc(100% - 10px);
    height: 100%;
    background-color: #e8e8e8;
    padding:5px;
    overflow-y: scroll;
    overflow-x: hidden;
    top : 0;
    left: 0;
}

.container-customer{
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;
    text-align: center;
}

.form-customer {
    display:  grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    flex-direction: row;
}

.customer-grid-1 {
    grid-column: 1 / 6;
    grid-row: 1;
    padding-top: 10px;
}

.customer-grid-2 {
    grid-column: 1 / 4;
    grid-row: 2 / 5;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.customer-grid-2-title {
    grid-column: 1 / 3;
    grid-row: 1;
    padding-top: 10px;
}

.customer-grid-2-1 {
    grid-column: 1;
    grid-row: 2;
    padding-top: 10px;
}

.customer-grid-2-2 {
    grid-column: 2;
    grid-row: 2;
    padding-top: 10px;
}


.customer-grid-3 {
    grid-column: 1 / 6;
    grid-row: 5;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
}

.customer-grid-3-title {
    grid-column: 1;
    grid-row: 1;
    padding-top: 10px;
}

.customer-grid-4 {
    grid-column: 1 / 3;
    grid-row: 6 / 9;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    max-height:  300px;
    overflow-y: scroll;
}

.customer-grid-4-title {
    grid-column: 1;
    grid-row: 1;
    padding-top: 10px;
}

.customer-grid-5 {
    grid-column: 4 / 6;
    grid-row: 2 / 5;
    padding-top: 10px;
}

.customer-grid-8 {
    grid-column: 1 / 6;
    grid-row: 9;
    padding-top: 10px;
}

.customer-grid-9 {
    grid-column: 3 / 6;
    grid-row: 6 / 9;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    max-height:  300px;
    overflow-y: scroll;
}

.customer-grid-9-1 {
    grid-column: 3 / 6;
    grid-row: 6 / 9;
    padding: 10px;
}

.table-orders {
    width: 100%;
}
.order {
    width: 90%;
    background-color: white;
    min-height: 20px;
    padding: 10px;
    margin: 2px auto;
    font-size: 13px;
    border: 1px black solid;
    cursor: pointer;
}
