.list-items {
    width: calc(100% - 30px);
    max-height: 100%;
   overflow-y: scroll;
   overflow-x: hidden;
}

.item-container {
    width: 90%;
    margin: auto;
}

.filter-container {
    width: 20%;
    float: left;
}
