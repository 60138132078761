.container-upload-file {
    width: 90%;
    margin: 30px auto;
}

.container-input-file {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.img-upload {
    background-color: rgb(230, 230, 230);
    margin: auto;
    position: relative;
}

.btn-delete {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    padding: 10px;
}
