.container-orders {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow: scroll;
}

.modal-order {
    background-color: #e8e8e8;
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    overflow: scroll;
}

.form-order {
    display:  grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    flex-direction: row;
}

.container-table-hide {
    display: none;
}

.order-grid-1 {
    grid-column: 1 / 5;
    grid-row: 1;
    padding-top: 10px;
}

.order-grid-2 {
    grid-column: 1 / 3;
    grid-row: 2 /  4;
    padding: 10px;
    padding-left: 20px;
    overflow-y: scroll;
    max-height: 350px;
}

.order-grid-3 {
    grid-column: 3 / 5;
    grid-row: 2 /  4;
    padding: 10px;
    padding-left: 20px;
}

.order-grid-4 {
    grid-column: 1 / 5;
    grid-row: 4;
    padding: 10px;
    padding-left: 20px;
}

.order-grid-5 {
    grid-column: 1 / 5;
    grid-row: 5 / 7;
    padding: 10px;
    padding-left: 20px;
}

.order-grid-6{
    grid-column: 5;
    grid-row: 1 / 7;
    padding: 10px;
    padding-left: 20px;
}
