.form-notification {
    width:90%;
    padding:20px;
    background-color: white;
}

.notification-card {
    width:90%;
    margin:20px auto;
    padding:20px;
    background-color: white;
}
