.container-categories {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.modal-category {
    width: 60%;
    min-height: 100px;
    margin: 150px auto;
    background-color: white;
    padding: 20px;
    text-align: center;
    max-height: 800px;
    overflow: scroll;
}

.modal-category .title {
    font-size: 25px;
}
