.launch-visual-container {
    width:50%;
    margin: auto;
    background-color: white;
    padding: 20px;
}

.launch-visual {
    margin: auto;
    max-width: 50%;
}

.page-container {
    overflow-y: scroll;
}
