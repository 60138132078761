.container-form-login  {
    width: 75%;
    height: 25vh;
    background-color: white;
    margin: auto;
    margin-top: 100px;
    justify-content: center;
    text-align: center;
    align-items: center;
    align-self: center;
    align-content: center;
    padding: 20px;


  
}

.containerLogo  .logo {
  width: 100px;
  height:100px;
}
.containerLogo{
  text-align: center;
  
}


.btn-login {
    width:28vw;
    margin-top: 4vh;
}
