.burger-menu {
    width: 250px;
    height: 100%;
    transition-property: width;
    transition-duration: 0.5s;
    background-color: rgb(242, 242, 242);
}

.burger-menu-hide {
    width: 0px;
    height: 100%;
    overflow: hidden;
    transition-property: width;
    transition-duration: 0.5s;
}

.nav-link {
    width: 100%;
    padding: 15px;
}

.nav-link-title {
    cursor: pointer;
}
