.container-users {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.modal-user {
    position: absolute;
    z-index: 1;
    width: calc(100% - 20px);
    margin: 10px;
    height: 100%;
    background-color: #e8e8e8;
    overflow-y: scroll;
    top : 0;
    left: 0;
}

.user-container {
    background-color: white;
    padding:10px;
}
