.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 90px;
}

.navbar-content {
    display: flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items: center;
}

.navbar-title {
    margin-left: 20px;
}

.navbar-actions {
    display: flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items: center;
}

.navbar-menu-burger {
    cursor: pointer;
}

.navbar-sign-out {
    cursor: pointer;
}

.navbar-actions .btn-action {
    margin-right: 20px;
}

.logo-title {
    width: 150px;
    cursor: pointer;
}
